import React from "react";
import Card from "../../../components/Cards";
import Checkpoints from "./TabComponents/Checkpoints";
import Events from "./TabComponents/Events";

const Tab = ({ driverFreight, checkpoints, events, setViewport }) => {
    return (
        <section className="h-screen flex w-full" style={{ "--heightt": "calc(100vh - 120px)", height: "var(--heightt)" }}>
            <div className="w-1/2 lg:w-2/5 lg:pr-2 xs:pr-0 lg:mb-0 xs:mb-4">
                <Card className="h-full">
                    <div>
                        <Events driverFreight={driverFreight} events={events} />
                    </div>
                </Card>
            </div>

            <div className="w-1/2 lg:w-3/5 lg:mb-0 xs:mb-4">
                <Card className="h-full">
                    <div>
                        <Checkpoints driverFreight={driverFreight} checkpoints={checkpoints} setViewport={setViewport} />
                    </div>
                </Card>
            </div>
        </section>
    );
};
export default Tab;
